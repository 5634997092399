import React from "react"
import mail from '../assets/mail1.png'
import phone from '../assets/phone1.png'
import { ReactComponent as FacebookIcon } from '../assets/facebook.svg';
import { ReactComponent as LinkedinIcon } from '../assets/linkedin.svg';
import { ReactComponent as InstagramIcon } from '../assets/instagram.svg';
import { Link } from "react-router-dom"
import EmailLink from "./maillink";
import PhoneNumberLink from "./phonenumber";

export default function Contact ()  {

    return <>
    
    <section className="contact">       
        <h1 className="text-4xl font-bold"> Contact Us</h1>


<div className="my-5">
        <div className="flex mb-2">
            <img className="me-2" src={mail} alt="" width={50}/>
            <EmailLink email="info@dr-recruiter.com"></EmailLink>
        </div>

        <div className="flex mb-2">
            <img className="me-2" src={phone} alt="" width={50} />
            <PhoneNumberLink phoneNumber={'01111336553'}/>
        </div>

        <div className="flex mb-2">
            <img className="me-2" src={phone} alt="" width={50} />
            <PhoneNumberLink phoneNumber={'01154568838'} />
        </div>

        <div className="flex mb-2">
            <img className="me-2" src={phone} alt="" width={50} />
            <PhoneNumberLink phoneNumber={'01110053765'}/>
        </div>
    </div>

        <div className="flex">
        <Link to='https://www.facebook.com/profile.php?id=61557411092317&mibextid=LQQJ4d' target="_blank"><FacebookIcon  /></Link>
        <Link to='https://www.linkedin.com/in/dr-recruiter-b098a51b5?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app' target="_blank" ><LinkedinIcon  /></Link>
        <Link to='https://www.instagram.com/dr.recruiter?igsh=MWtxdW90dWRsbGQxbA==' target="_blank" ><InstagramIcon  /></Link>
        </div>

    </section>
    
    
    </>
}