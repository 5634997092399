
import React from "react";
import Group1 from "../assets/Group1.png"
import Group2 from "../assets/Group2.png"
import Group3 from "../assets/Group3.png"
import Group4 from "../assets/Group4.png"
import pattern from '../assets/pattern.png'

function HowItWorks () {
return <>
<div className="pattern2">
    </div>

<div className="HowitWorks">



<div className="container"> 
<div className="mb-3 ">
<h3>Whats the Function</h3>
<h1 className="text-2xl font-bold">Let's see how it works</h1>
</div>


<div className="lg:flex justify-center my-10">

<div className="lg:w-1/6 md:w-3/4 ps-10 text-left mb-4 flex lg:block">
<img className="Num-Icon" src={Group1} alt="" />
<div className="ms-5 lg:ms-0">
        {/* <h3 className="font-bold text-lg mt-3 ">Set disbursement Instructions</h3> */}
        <h6 className="text-md mt-3 font-bold">Dr. Recruiter is the first specialized medical job application in Egypt and the Gulf region, focused on showcasing job opportunities within the medical sector.</h6>
    </div>
</div>

<div className="lg:w-1/6 md:w-3/4 ps-10 text-left mb-4 flex lg:block">
<img className="Num-Icon" src={Group2} alt="" />
<div className="ms-5 lg:ms-0">
        {/* <h3 className="font-bold text-lg mt-3 ">Set disbursement Instructions</h3> */}
        <h6 className="text-md mt-3 font-bold">It provides a platform tailored to doctors, pharmacists, and technicians across all specialties, helping them find rewarding career paths.</h6>
    </div>
</div>

<div className="lg:w-1/6 md:w-3/4 ps-10 text-left mb-4 flex lg:block ">
<img className="Num-Icon" src={Group3} alt=""/>
    <div className="ms-5 lg:ms-0">
        {/* <h3 className="font-bold text-lg mt-3 ">Set disbursement Instructions</h3> */}
        <h6 className="text-md mt-3 font-bold"> The application facilitates connections between medical professionals and leading medical centers and clinics in Egypt and the Gulf, where competitive salaries are offered</h6>
    </div>
</div>

<div className="lg:w-1/6 md:w-3/4 ps-10 text-left mb-4 flex lg:block">
<img className="Num-Icon" src={Group4} alt="" />
<div className="ms-5 lg:ms-0">
        {/* <h3 className="font-bold text-lg mt-3 ">Set disbursement Instructions</h3> */}
        <h6 className="text-md mt-3 font-bold "> Additionally, it enables medical facilities to advertise their open positions, making the recruitment of qualified medical staff easier and more efficient throughout Egypt and the Gulf region.</h6>
    </div>
</div>

</div>
</div>
</div>
</>
}

export default HowItWorks;