import React from "react"
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom"
import classes from "./navbar.module.scss";
import logo from '../assets/Logo.png'

export default function Navbar ()  {

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const location = useLocation()
  const isHomePage = ['/', '/home'].includes(location.pathname);

    return <>
<nav>
<div className={isHomePage ? classes.navbar : classes.navbar2}>
      <div className={classes.Navcontainer}>

      <Link>
        <img src={logo} alt="" width={90}/>
      </Link>
  
      <div className={classes.navlinks}>
      
          <ul>
            <li>
              <NavLink to='/home'  onClick={() => handleNavigation("/home")}>Home</NavLink>
            </li>
        
        <li>
        <NavLink to="/contact"  onClick={() => handleNavigation("/contact")}>Contact</NavLink>
        </li>
          </ul>

      </div>
     </div>

    </div>

</nav>

    </>
}
