import { Link } from "react-router-dom";
import style from './contact.module.scss'

const PhoneNumberLink = ({ phoneNumber }) => {

    const whatsappLink = `https://wa.me/${phoneNumber}`;
  
    return (
      <Link  to={whatsappLink} target="_blank" 
      className={style.contact}>
        {phoneNumber} 
    
      </Link>
    );
  };
  
  export default PhoneNumberLink