import React from "react";
import Header from "../Header";
import Features from "../Features";
import Section2 from "../Section2";
import HowItWorks from "../howItWorks";
import CoreFeatures from "../CoreFeatures";

function Home () {
  
    return <>
    
    <Header />
      <Features />
      <Section2 />
      <HowItWorks />
      <CoreFeatures />
    
    </>
}
export default Home;