import './App.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from './Contact/Contact';
import Features from './Features';
import Header from './Header';
import Section2 from './Section2';
import HowItWorks from './howItWorks';
import Layout from './Layout/Layout';
import Home from './Home/Home';

const App = () => {

  return (
    <BrowserRouter>
     <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="contact" element={<Contact />} />
      </Route>
    </Routes>
     </BrowserRouter>
   

  );
};

export default App;
