import React from "react"
import style from './header.module.scss'
import Navbar from "../Navbar/Navbar";
import img from "../assets/mockup.png"
import pattern from '../assets/pattern.png'

function Header () {

    return <> 
    
    <div className={style.header}>
    <div className="pattern">
    </div>

<div className={style.container} >
    <div className="lg:flex justify-center my-5">
        <div className="flex-col lg:w-1/2">
        <h1 className="text-5xl font-bold text-white">
        Experience your ultimate mobile application
        </h1>

        <h5 className="text-white text-sm mt-6 w-2/3">Get your blood tests delivered at let home collect sample from the victory of the managments that supplies best
            design system guidelines ever.</h5>
        </div>

    <div className="lg:w-1/2 md:1/2">
    <img className={style.Image} src={img} alt=""/>
    </div>

    </div>
    
    </div>
    </div>

    </>
}
export default Header;