import React from "react";
import icon from '../assets/icon1.png'
import icon2 from '../assets/icon2.png'
import icon3 from '../assets/icon.png'

function Features () {
    return <>
    <div className="relative my-10 container">

        <h1 className="font-bold text-2xl m-auto text-center ">Meet exciting feature of Dr Recruiter</h1>

    <div className="lg:flex justify-center my-10">

        <div className="lg:w-1/4 mb-3 text-center">
            <img className="m-auto" src={icon} alt="" />
            <h3 className="font-bold my-4"> Job posting</h3>
            {/* <h6 className="w-1/2 m-auto ">Get your blood tests delivered at
        home collect a sample from the
        your blood tests</h6> */}
        </div>

        <div className="lg:w-1/4 mb-3 text-center">
            <img className="m-auto" src={icon2} alt="" />
            <h3 className="font-bold my-4">Applying for a Job </h3>
            {/* <h6 className="w-1/2 m-auto">Get your blood tests delivered at
        home collect a sample from the
        your blood tests</h6> */}
        </div>


        <div className="lg:w-1/4 text-center">
            <img className="m-auto" src={icon3} alt="" />
            <h3 className="font-bold my-4">Communication with recruiters by chat</h3>
            {/* <h6 className="w-1/2 m-auto">Get your blood tests delivered at
        home collect a sample from the
        your blood tests</h6> */}
        </div>


        </div>

    </div>
    
    
    </>
}
export default Features;