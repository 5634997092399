import React from "react";
import img from "../assets/app2.png"
import { ReactComponent as Features } from '../assets/Group.svg';
import { ReactComponent as Features2 } from '../assets/Group10.svg';

function Section2 () {
    return<>

<div className="lg:flex justify-evenly my-16 container">


    <div className="mt-16">
        <h2 className="font-bold text-2xl w-3/5">Smart Jackpots that you may love this </h2>

        <div className="flex mt-10">
            <span>
                <Features2 />
            </span>

            <span className="ms-3 ">
                <h4 className="font-bold text-sm">Smart Features</h4>
                <h6 className="mt-2 text-sm">Get your blood tests delivered at let home collect </h6>
            </span>
        </div>

        <div className="flex mt-10">
        <span>
            <Features />
        </span>

        <span className="ms-3">
            <h4 className="font-bold text-sm">Secure Content</h4>
            <h6 className="mt-2 text-sm">Get your blood tests delivered at let home collect </h6>
        </span>
        </div>

    </div>

    <img className="rounded-lg" src={img} alt="" width={350}/>


</div>
    
    </>
}
export default Section2;