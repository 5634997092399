import React from "react";
import img from "../assets/app3.png"
import { ReactComponent as Playstore } from '../assets/Group 150.svg';
import { ReactComponent as Appstore } from '../assets/Group 337.svg';
import { Link } from "react-router-dom";

function CoreFeatures () {
return <>


<div className="lg:flex justify-evenly my-16 container">

<img className="rounded-lg" src={img} alt="" width={350} />

<div className="flex my-10 items-center lg:w-1/3 ">
        <Link to="https://play.google.com/store/apps/details?id=com.dr_recruiter" target="_blank"> 
        <div className="flex bg-black px-2 py-1 rounded-md italic me-7">
             <Playstore width={40} />       
                <div className="text-white font-bold p-2">
                <h6 className="text-xs uppercase">Get It On</h6>
                <h3>Google Play</h3>
                </div>
            </div>
            </Link>

            <Link to="" target="_blank">
            <div className="flex bg-black px-2 rounded-md italic">
             <Appstore width={55} /> 
                <div className="text-white font-bold p-2">
                <h6 className="text-xs">Download on the </h6>
                <h3>App Store</h3>
                </div>
            </div>
            </Link>
        </div>



</div>

</>

}
export default CoreFeatures;